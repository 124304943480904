/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// require('typeface-inter')
import "./src/styles/site.css"

import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import PageLayout from "./src/layouts/PageLayout"
import ExtraLayout from "./src/layouts/ExtraLayout"
import SimpleLayout from "./src/layouts/SimpleLayout"

const extraLayoutPages = ["/extralife"]
const simpleLayoutPages = ["/go/"]
// wrap all pages in the standard PageLayout
export function wrapPageElement({ element, props }) {
    const pagePath = props.location.pathname
    if (pagePath.includes(extraLayoutPages)) {
        return <ExtraLayout {...props}>{element}</ExtraLayout>
    } else if (pagePath.includes(simpleLayoutPages)) {
        return <SimpleLayout {...props}>{element}</SimpleLayout>
    } else {
        return (
            <ParallaxProvider>
                <PageLayout {...props}>{element}</PageLayout>
            </ParallaxProvider>
        )
    }
}
