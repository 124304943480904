import React from "react"
// Import Components
import MenuItem from "@/components/organisms/MenuItem"
// Import Styles
import "@/styles/mega-menu.css"
// Import Data
import { megaMenuData } from "@/content/megaMenuData"

const MegaMenu = () => {
    return (
        <nav className="mega-menu-wrapper hidden lg:flex px-2 items-center justify-center self-stretch">
            <ul className="flex items-center h-full w-full justify-center">
                {megaMenuData.map(({ title, href, children }, index) => {
                    return (
                        <MenuItem key={index} {...{ title, href, children }} />
                    )
                })}
            </ul>
        </nav>
    )
}

export default MegaMenu
