import React from "react"
import CookieConsent from "react-cookie-consent"
import Link from "@/components/atoms/Link"

const CookieNotice = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Got it!"
            // cookieName="modsquad"
            acceptOnScroll={true}
            acceptOnScrollPercentage={25}
            style={{
                // background: "#2B3D54",
                background: "#222222",
                color: "#ffffff",
                fontFamily: "Noe",
                fontWeight: "200",
                fontSize: "17px",
            }}
            buttonStyle={{
                // background: "#F2360D",
                background: "#FCB134",
                color: "#222222",
                fontFamily: "proxima-nova",
                fontWeight: "bold",
                textTransform: "uppercase",
                fontStyle: "italic",
                fontSize: "14px",
                padding: "12px",
                width: "100px",
            }}
            expires={150}
        >
            To ensure you get the best experience, we use cookies on our
            website. By continuing to use our website you agree to our use of
            cookies.{" "}
            <Link
                sx={{ color: "#fff", textDecoration: "underline" }}
                aria-label="learn more about cookies"
                role="button"
                tabIndex="0"
                className="cc-link"
                href="https://modsquad.com/privacy-and-cookies/"
                target="_blank"
            >
                Review our policies
            </Link>
            .
        </CookieConsent>
    )
}

export default CookieNotice
