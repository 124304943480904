import React from "react"
import Link from "@/components/atoms/Link"

const NotificationBar = () => (
    <div
        id="notification-bar"
        sx={{ bg: "#002D54", textAlign: "center", p: 3 }}
    >
        <p
            sx={{
                color: "white",
                fontWeight: "400",
                fontFamily: "sans",
                fontSize: 16,
            }}
        >
            <Link
                href="/extralife"
                sx={{
                    color: "white",
                    // fontFamily: 'sans',
                    // fontWeight: "bold",
                    "&:hover": {
                        color: "contiYellow",
                        textDecoration: "underline",
                    },
                }}
            >
                Join us November 2nd at 8AM PST for  <span className="font-bold">Extra Life</span>!
            </Link>
        </p>
    </div>
)
export default NotificationBar
