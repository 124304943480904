import React from "react"
import Link from "@/components/atoms/Link";
import MenuItemDropdown from "@/components/organisms/MenuItemDropdown"
import Button from "@/components/atoms/Button"

const MenuItem = ({ title, href, children }) => {
    return (
        <li
            className={`menu-item h-full content-center px-[10px]${children ? " has-children" : ""}`}
        >
            {children ? (
                <>
                    <Button variant="linkMenu" className="cursor-default">
                        {title}
                    </Button>
                    {children && <MenuItemDropdown children={children} />}
                </>
            ) : (
                <Link
                    to={href}
                    className="font-inter text-msqblack text-base link-underline inline-block"
                    context="header"
                    variant="styles.a.topNav"
                >
                    {title}
                </Link>
            )}
        </li>
    )
}

export default MenuItem
