import React from "react"
import { Link as GatsbyLink } from "gatsby"

import sendToTagManager from "@/utils/analyticsHelper"
const isBrowser = typeof window !== "undefined"

const Link = ({ children, href = null, to = null, variant = "styles.a.default", onClick = null, context = null, action = null, className="", ...props }) => {

    // allow either href or to prop to work
    const destination = href ?? to;
    // double check any destinations for internal links
    const internal = /^\/(?!\/)/.test(destination)
    // test for files ( don't use gatsby links for files)
    const isFile = /\.[0-9a-z]+$/i.test(destination)
    const isTel = /tel:/i.test(destination)
    const isMailto = /mailto:/i.test(destination)
    const isCTA = /primarysecondary/i.test(destination)
    // if no action was specified, test against common anchor patterns to identify it
    if ( ! action ){
        switch( true ){
            case isFile:
                action = 'content-download';
                break;
            case isTel:
                action = 'telephone';
                break;
            case isMailto:
                action = 'mailto';
                break;
        }
    }

    const useGatsbyLink = internal && !isFile;

    const analyticsDispatcher = (e) => {
        //console.log( 'testing link: ', { destination, useGatsbyLink, isFile, isTel, isMailto, action, context, isBrowser })
        if (isBrowser) {
            sendToTagManager({
                action: action ?? 'navigation',
                label: context ?? 'body',
                category: 'Links & Buttons',
                target: to ?? href,
            })
        }
        // handle any additional onclick attributes pushed to component
        if (onClick && typeof onClick === 'function') onClick();
    }
    return (
        (useGatsbyLink ? (
            <GatsbyLink
                {...props}
                // {...(className
                //     ? { className: className }
                //     : { sx: { variant: variant } })}
                className={className}
                sx={{
                    variant: variant,
                }}
                to={destination}
                onClick={() => analyticsDispatcher()}
            >
                {children}
            </GatsbyLink>
        ) : (
            <a
                {...props} href={destination} onClick={() => analyticsDispatcher()}
                // {...(className
                //     ? { className: className }
                //     : { sx: { variant: variant } })}
                className={className}
                sx={{
                    variant: variant,
                }}
            >
                {children}
            </a>
        ))
    )
}

export default Link;
