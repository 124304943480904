import React from "react"

// Replaces ThicLine & BlogHr & default <hr>
const Divider = ({ variant = "default", className, height, width }) => {
    const heightVariants = {
        1: "border-t",
        2: "border-t-2",
        8: "border-t-8",
    }

    const widthVariants = {
        large: 'w-full',
        medium: 'max-w-full md:max-w-[736px]',
        small: 'w-[280px] md:w-[500px]'
    }

    const findClasses = className ? ` ${className}` : ""
    let dividerStyles = ""
    switch (variant) {
        case "thin":
            dividerStyles = `${height ? heightVariants[height] : "border-t"} ${width ? widthVariants[width] : " w-[280px] md:w-[500px]"} my-2 mx-auto`
            break
        case "thick":
            dividerStyles = `${height ? heightVariants[height] : "border-t-16"} my-0 border-t-current`
            break
        case "menu":
            dividerStyles = `${height ? heightVariants[height] : "border-t-1"} mb-[14px] w-full border-t-current`
            break
        default:
            dividerStyles = `${height ? heightVariants[height] : "border-t"} border-t-current`
            break
    }
    const wrapperClasses =
        variant === "menu" || variant === "submenu"|| variant === "thin" ? "" : "mx-auto"
    return (
        <div className={wrapperClasses}>
            <hr className={dividerStyles.concat(findClasses)} />
        </div>
    )
}

export default Divider
