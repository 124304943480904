import React from "react"

const Stack = ({gap, children, className=""}) => (
    <div
        className={className}
        sx={{
            display: "flex",
            flexDirection: "column",
            gap: gap,
        }}
    >
        {children}
    </div>
)
export default Stack
