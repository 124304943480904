import React from "react"
import { Button } from "theme-ui"
import Link from "@/components/atoms/Link"

const ButtonLink = ({ to, bg, color, variant = "solid", children }) => {
    const solidStyles = {
        mt: 24,
        display: "inline-block",
        py: 2,
        px: 3,
        bg: bg,
        color: color,
        fontStyle: "normal",
        fontSize: 1,
        fontWeight: 900,
        border: 0,
        "&:hover": {
            bg: "contiYellow",
            color: "tire",
        },
    }

    const outlineStyles = {
        display: "inline-block",
        py: 2,
        px: 3,
        bg: bg ? bg : "transparent",
        color: color ? color : "white",
        fontStyle: "normal",
        fontSize: [1, 1, 2],
        fontWeight: 900,
        border: "2px solid white",
        "&:hover": {
            borderColor: "contiYellow",
            color: "contiYellow",
            bg: "transparent",
        },
    }
    const sxStyles = variant === "outline" ? outlineStyles : solidStyles
    return (
        <div
            sx={{
                textAlign: ["center", "left"],
                display: "inline-block",
                mx: "auto",
            }}
        >
            <Link target="_blank" rel="noreferrer" href={to}>
                <Button sx={sxStyles}>{children}</Button>
            </Link>
        </div>
    )
}

export default ButtonLink
