/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
// Components
import NotificationBar from "@/components/molecules/notification-bar"
import Footer from "@/components/organisms/Footer.js"
import Header from "@/components/organisms/Header.js"
import MobileMenu from "@/components/organisms/MobileMenu"
// Styles
import "@/styles/mobile-nav.css"

// const ContactLink = (props) => {
//     // Disable link if it matches current pathname
//     let current = typeof window !== "undefined" ? window.location.pathname : ""
//     let isDisabled = current === props.to

//     return (
//         <div>
//             {isDisabled ? (
//                 <div
//                     onClick={() => {
//                         props.setContactMenuOpen(false)
//                     }}
//                     sx={{ cursor: "pointer", variant: "styles.a.press" }}
//                 >
//                     {props.children}
//                 </div>
//             ) : (
//                 <Link
//                     sx={{
//                         variant: "styles.a.press",
//                     }}
//                     to={props.to}
//                 >
//                     {props.children}
//                 </Link>
//             )}
//         </div>
//     )
// }

const PageLayout = (props) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    // If mobile menu is open and the notificationBar exists then do a little calculation for start of menu
    useEffect(() => {
        document.body.style = mobileMenuOpen ? "overflow: hidden" : ""
        if (mobileMenuOpen) {
            const mobileMenu = document.querySelectorAll(".mobile-menu")[0]
            if (typeof window !== "undefined") {
                window.scrollY > 56
                    ? mobileMenu.classList.add("mobile-menu-open-scrolled")
                    : mobileMenu.classList.add("mobile-menu-open")
            }
        }
    }, [mobileMenuOpen])

    // const [contactMenuOpen, setContactMenuOpen] = useState(false)

    return (
        <div>
            <div className={`page-wrapper flex-grow relative`}>
                {/* <NotificationBar /> */}

                <Header
                    mobileMenuOpen={mobileMenuOpen}
                    setMobileMenuOpen={setMobileMenuOpen}
                />
                {mobileMenuOpen && (
                    <MobileMenu setMobileMenuOpen={setMobileMenuOpen} />
                )}
                {/* menu overlay on hover */}
                <div id="menu-overlay"></div>

                <div>{props.children}</div>
            </div>
            {/* Hide if mobile menu is open */}
            {!mobileMenuOpen && <Footer />}
        </div>
    )
}

export default PageLayout
