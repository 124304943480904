import React from "react"
// Components
import Link from "@/components/atoms/Link"
import FooterSimple from "@/components/organisms/footer-simple"
// Images
import Logo from "@/images/msq-logo.svg"

const SimpleLayout = ({ children }) => {
    return (
        <div>
            {/* <div sx={{ flexGrow: 1 }}> */}
            <header
                sx={{
                    p: 3,
                    display: "flex",
                    flex: "justify-between",
                    alignItems: "center",
                    zIndex: 100,
                }}
            >
                <div
                    sx={{
                        flex: 1,
                    }}
                >
                    <div sx={{}}>
                        <Link sx={{}} to="/">
                            <img
                                src={Logo}
                                sx={{
                                    width: 140,
                                    mx: ["auto", "auto", 0],
                                    display: "inline-block",
                                }}
                                alt="ModSquad Logo"
                            />
                        </Link>
                    </div>
                </div>
            </header>

            <main>{children}</main>

            {/* </div>  */}
            <FooterSimple />
        </div>
    )
}

export default SimpleLayout
