import React from "react"
import Link from "@/components/atoms/Link";
import Button from "@/components/atoms/Button";
// Components
import Stack from "@/components/organisms/stack"

const NavBlock = (props) => (
    <div
        sx={{
            m: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            // px: [4, 0],
            color: "#ffffff",
            width: [200, 200, "auto"],
            textAlign: ["center", "center", "left"],
            // flexBasis: ['auto', 0, 'auto'],
            // bg: 'green',
        }}
    >
        {/* <p sx={{
            fontFamily: "sans",
            fontSize: [0],
            fontWeight: "bold",
            letterSpacing: '1px',
            textTransform: 'uppercase',
            mb: [1,2],
        }}>
            {props.title}
        </p> */}
        {props.children}
    </div>
)

const NavItemWrap = (props) => (
    <p
        sx={{
            fontSize: [0, 15],
            fontFamily: "sans",
            // textTransform: 'uppercase',
            // letterSpacing: '1px',
        }}
    >
        {props.children}
    </p>
)

const MobileLinkWrap = (props) => (
    <p
        sx={{
            fontFamily: "sans",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 1,
            letterSpacing: "1px",
        }}
    >
        {props.children}
    </p>
)

const MobileLinkWrapSmol = (props) => (
    <p
        sx={{
            fontFamily: "sans",
            textTransform: "uppercase",
            fontSize: 14,
            letterSpacing: "1px",
        }}
    >
        {props.children}
    </p>
)

const ListLink = (props) => (
    <NavItemWrap>
        <Link
            sx={{
                variant: "styles.a.mobilemenu",
            }}
            to={props.to}
            context="footer"
        >
            {props.children}
        </Link>
    </NavItemWrap>
)
const NavHeadingWrap = (props) => (
    <div>
    <h3 className="font-text font-normal text-base text-white">
        {props.children}
    </h3>
   <hr sx={{color: 'white', mt: 0, mb: 1}}/>
   </div>
)

const FooterNav = ({ siteTitle }) => {
    // const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    return (
        <div sx={{ mb: [5, 6] }}>
            <div
                sx={{
                    display: ["none", "none", "flex"],
                    mt: 4,
                    mb: 4,
                    maxWidth: [400, "col10"],
                    mx: "auto",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: [3, 4],
                    justifyContent: "center",
                }}
            >
                <NavBlock title="Services">

                    <NavHeadingWrap>Customer Care</NavHeadingWrap>
                    <ListLink to="/cx-services/customer-support">
                        Customer Support
                    </ListLink>
                    <ListLink to="/cx-services/technical-support">
                        Technical Support
                    </ListLink>
                    <ListLink to="/cx-services/consulting-services">
                        CRM & Tool Integration
                    </ListLink>

                    <br/>
                    <NavHeadingWrap>Moderation</NavHeadingWrap>
                    <ListLink to="/cx-services/content-moderation">
                        Content Moderation
                    </ListLink>
                    <ListLink to="/cx-services/trust-and-safety">
                        Trust &amp; Safety
                    </ListLink>
                    <ListLink to="/cx-services/community-management">
                        Community Management
                    </ListLink>
                    <ListLink to="/cx-services/social-media">
                        Social Media
                    </ListLink>
<br/>
                    <NavHeadingWrap>Data, Security, Compliance</NavHeadingWrap>
                    <ListLink to="/security">
                        The Cubeless Platform
                    </ListLink>

                    {/* <NavItemWrap>
                        <Link                            sx={{
                                variant: "styles.a.mobilemenu",
                            }}
                            href="https://cubeless.io"
                            target="_blank"
                        >
                            Cubeless Secure Workspace
                        </Link>
                    </NavItemWrap> */}
                </NavBlock>

                <NavBlock title="">
                <NavHeadingWrap>AI & Automation</NavHeadingWrap>
                    <ListLink to="/ai-and-automation">Overview</ListLink>
                    <ListLink to="/cx-services/consulting-services">CRM Optimization</ListLink>
<br/>
                    <NavHeadingWrap>Industries</NavHeadingWrap>
                    <ListLink to="/industries/gaming">Gaming / Player Experience</ListLink>
                    <ListLink to="/industries/travel">Travel & Tourism</ListLink>
                    <ListLink to="/industries/metaverse">Metaverse</ListLink>
                    <ListLink to="/industries/crypto-and-nfts">Crypto and NFTs</ListLink>
                    <ListLink to="/industries/technology">Technology</ListLink>
                    <ListLink to="/industries/e-commerce-and-retail">E-commerce & Retail</ListLink>
                </NavBlock>


                <NavBlock title="Why ModSquad?">
                    <NavHeadingWrap>Why us?</NavHeadingWrap>
                    <ListLink to="/why-modsquad">Why ModSquad?</ListLink>
                    <ListLink to="/remote">Remote Experts</ListLink>
                    <ListLink to="/spend-less-deliver-more">
                        Low Cost, High Quality
                    </ListLink>
                    <ListLink to="/mods">The Mods</ListLink>
                    <br/>
                    <NavHeadingWrap>Resources</NavHeadingWrap>
                    <ListLink to="/the-blog">The Blog</ListLink>
                    <NavItemWrap>
                        <Link                            sx={{
                                variant: "styles.a.mobilemenu",
                            }}
                            href="https://archives.modsquad.com/"
                        >
                            The Blog Archives
                        </Link>
                    </NavItemWrap>
                    <ListLink to="/resources">Resource Library</ListLink>
                </NavBlock>

                <NavBlock title="The Company">
                <NavHeadingWrap>Company</NavHeadingWrap>
                    <ListLink to="/about">About Us</ListLink>
                    <ListLink to="/our-work">Our Work</ListLink>
                    <ListLink to="/press-center">Press</ListLink>
                    <NavItemWrap>
                        <Link                            sx={{
                                variant: "styles.a.mobilemenu",
                            }}
                            href="https://join.modsquad.com/careers/"
                            target="_blank"
                        >
                            Careers & Gigs
                        </Link>
                    </NavItemWrap>
                    {/* <ListLink to="/the-blog">The Blog</ListLink>
                    <ListLink to="/resources">Resource Library</ListLink> */}
                </NavBlock>

            </div>

            {/* Mobile Menu */}
            <div
                sx={{
                    display: ["block", "block", "none"],
                    px: 4,
                    mt: 3,
                    textAlign: "center",
                }}
            >
                <Stack gap="3">
                    <MobileLinkWrap>
                        <Link
                            context="footer"
                            sx={{ variant: "styles.a.mobilemenu" }}
                            to="/cx-services"
                        >
                            CX Services
                        </Link>
                    </MobileLinkWrap>
                    <MobileLinkWrap>
                        <Link
                            context="footer"
                            sx={{ variant: "styles.a.mobilemenu" }}
                            to="/why-modsquad"
                        >
                            Why ModSquad?
                        </Link>
                    </MobileLinkWrap>
                    <MobileLinkWrap>
                        <Link
                            context="footer"
                            sx={{ variant: "styles.a.mobilemenu" }}
                            to="/our-work"
                        >
                            Our Work
                        </Link>
                    </MobileLinkWrap>
                </Stack>
            </div>

            <div
                sx={{
                    maxWidth: "col6",
                    mx: "auto",
                    textAlign: "center",
                    mt: [4, 4, 0],
                }}
            >

                <Link to="/contact" context="footer">
                    <Button
                        variant="primaryMenu"
                    >
                        Contact us
                    </Button>
                </Link>
            </div>

            <div
                sx={{
                    display: ["block", "block", "none"],
                    px: 4,
                    mt: 5,
                    textAlign: "center",
                }}
            >
                <Stack gap="3">
                    <MobileLinkWrapSmol>
                        <Link
                            context="footer"
                            sx={{ variant: "styles.a.mobilemenu" }}
                            to="/about"
                        >
                            About Us
                        </Link>
                    </MobileLinkWrapSmol>
                    <MobileLinkWrapSmol>
                        <Link
                            context="footer"
                            sx={{ variant: "styles.a.mobilemenu" }}
                            to="/the-blog"
                        >
                            The Blog
                        </Link>
                    </MobileLinkWrapSmol>
                    <MobileLinkWrapSmol>
                        <Link
                            context="footer"
                            sx={{ variant: "styles.a.mobilemenu" }}
                            href="https://join.modsquad.com/careers/"
                            target="_blank"
                        >
                            Careers
                        </Link>
                    </MobileLinkWrapSmol>

                    <MobileLinkWrapSmol>
                        <Link
                            context="footer"
                            sx={{ variant: "styles.a.mobilemenu" }}
                            href="https://join.modsquad.com/join-the-mods"
                            target="_blank"
                        >
                            Join the Mods
                        </Link>
                    </MobileLinkWrapSmol>
                </Stack>
            </div>
        </div>
    )
}

export default FooterNav
